import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';

import {environment} from 'environments/environment';

@Injectable({providedIn: 'root'})
export class RequestService {

    private endpoint = environment.apiEndpoint;

    public constructor(private http: HttpClient) {
    }

    public delete(uri: string, params?: object): Observable<any> {
        return this.http.delete<any>(this.buildUri(uri), params);
    }

    public get(uri: string, params?: object): Observable<any> {
        return this.http.get<any>(this.buildUri(uri), params);
    }

    public patch(uri: string, body?: any, params?: object): Observable<any> {
        return this.http.patch<any>(this.buildUri(uri), body, params);
    }

    public post(uri: string, body?: any, options?: object): Observable<any> {
        return this.http.post<any>(this.buildUri(uri), body, options);
    }

    public put(uri: string, params?: object): Observable<any> {
        return this.http.put<any>(this.buildUri(uri), params);
    }

    //#region Building URI

    private buildUri(uri: string): string {
        if (uri.startsWith('http://') || uri.startsWith('https://')) {
            return uri;
        } else {
            return `${this.endpoint}${uri}`;
        }
    }

    //#endregion

}
