import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthService} from './auth.service';
import {RequestService} from 'app/common/services/request.service';

import {observableApiRequest} from 'app/common/utils/service-utils';

@Injectable({providedIn: 'root'})
export class CommonService {

    constructor(
        private $request: RequestService,
        private $auth: AuthService
    ) {
    }

    //#region User authentication

    public login(username: string, password: string): Observable<LoginResponse> {
        return this.$request.post('/v1/auth/login', {username, password});
    }

    public signInWithGoogle(): Promise<void> {
        return this.$auth.getGoogleAuth().then((googleAuth: gapi.auth2.GoogleAuth) => {
            return googleAuth.signIn().then((googleUser: gapi.auth2.GoogleUser) => {
                return this.$auth.signInGoogleUser(googleUser);
            }).catch(error => {
                googleAuth.disconnect();
                if (error.error === 'popup_closed_by_user' || error.error === 'access_denied') {
                    throw new Error('Please follow the instructions in the popup to sign in with Google.');
                } else if (error.type === 'tokenFailed') {
                    throw new Error('You must sign in using your @focal.systems email.');
                } else {
                    throw new Error(error.message || 'Google sign in failed.');
                }
            });
        }).catch((googleAuthError: string) => {
            throw new Error(googleAuthError);
        });
    }

    //#endregion

    //#region Password change

    public changePassword(password: string): Observable<ChangePasswordResponse> {
        return this.$request.post('/v1/auth/password', {password});
    }

    //#endregion

    public getAllStores(): Observable<Array<any>> {
        return observableApiRequest(this.$request.get(`/v1/stores`, {params: {onlyActive: true}}));
    }

    public getAllUsers(): Observable<Array<any>> {
        return observableApiRequest(this.$request.get(`/v1/users`, {params: {onlyActive: true}}));
    }

}

//#region Common response interfaces

export interface LoginResponse {
    error: any;
    token: string;
    message: string;
}

export interface ChangePasswordResponse {
    success: string;
}

//#endregion
