import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthService} from 'app/common/services/auth.service';
import {ChangePasswordResponse, CommonService} from 'app/common/services/common.service';

import {RepeatPasswordValidator} from 'app/common/validators/repeat-password.validator';

@Component({
    selector: 'app-pages-auth-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss']
})
export class AuthPasswordComponent implements OnInit, OnDestroy {

    error: String | null;

    isLoading = false;

    passwordForm: FormGroup;

    constructor(
        private $auth: AuthService,
        private $common: CommonService,
        private router: Router,
        private fb: FormBuilder
    ) {
    }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.passwordForm = this.fb.group({
            newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(24)]],
            repeatPassword: ['', []]
        }, {
            validators: RepeatPasswordValidator
        });
    }

    //#region UI handling

    changePassword() {
        this.error = null;
        this.isLoading = true;
        this.$common.changePassword(this.newPassword.value)
            .subscribe(
                response => this.handleChangePasswordResponse(response),
                _ => this.handleChangePasswordError()
            );
    }

    get newPassword() {
        return this.passwordForm.get('newPassword');
    }

    logout() {
        this.$auth.logout().then(() => {
            this.router.navigate(['/auth/login']);
        });
    }

    //#endregion

    private handleChangePasswordError() {
        this.error = 'Updating password failed. Check your password and try again.';
        this.isLoading = false;
    }

    private handleChangePasswordResponse(response: ChangePasswordResponse) {
        if (response.success) {
            this.router.navigate(['']);
        } else {
            this.isLoading = false;
        }
    }

}
