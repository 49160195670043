import { ABTestService } from 'app/common/ab-test/ab-test.service';

export abstract class ABTestAbstractComponent {
    protected constructor(
        protected $abTest: ABTestService
    ) {
    }

    public hasABTestFlag(testName: string) {
        return this.$abTest.hasTestFlag(testName);
    }
}
