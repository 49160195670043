import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {environment} from 'environments/environment';

import {AuthService} from 'app/common/services/auth.service';
import {CommonService, LoginResponse} from 'app/common/services/common.service';

@Component({
    selector: 'app-pages-auth-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class AuthLoginComponent implements OnInit, OnDestroy {

    error: String | null;

    isGoogleProgress = false;

    isLoading = false;

    loginForm: FormGroup;

    constructor(
        private $auth: AuthService,
        private $common: CommonService,
        private router: Router,
        private fb: FormBuilder
    ) {
    }

    ngOnDestroy() {
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            username: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    //#region UI handling

    get appName() {
        return environment.name;
    }

    login() {
        this.error = null;
        this.isLoading = true;
        this.$common.login(this.username.value, this.password.value).subscribe(
            this.handleLoginResponse.bind(this),
            this.handleLoginError.bind(this)
        );
    }

    googleLogin() {
        this.error = null;
        this.isGoogleProgress = true;
        this.$common.signInWithGoogle().then(
            this.handleGoogleLoginSuccess.bind(this),
            this.handleGoogleLoginError.bind(this)
        );
    }

    get password() {
        return this.loginForm.get('password');
    }

    get username() {
        return this.loginForm.get('username');
    }

    //#endregion

    private handleLoginError(error) {
        const message = error.error ? error.error.message : undefined;
        this.error = message || 'Login failed. Check your username and password and try again.';
        this.isLoading = false;
    }

    private handleGoogleLoginError(error) {
        this.error = error.message;
        this.isGoogleProgress = false;
    }

    private handleGoogleLoginSuccess() {
        this.router.navigate(['']);
    }

    private handleLoginResponse(response: LoginResponse) {
        if (response && response.token) {
            this.$auth.setToken(response.token);
            this.router.navigate(['']);
        } else {
            this.error = response.message;
            this.isLoading = false;
        }
    }

}
