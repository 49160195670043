import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import { ABTestService } from 'app/common/ab-test/ab-test.service';

import {Observable, forkJoin} from 'rxjs';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/mergeMap'

import {AuthService} from 'app/common/services/auth.service';
import {CommonService} from "app/common/services/common.service";
import {GlobalService} from 'app/common/services/global/global.service';

import {StoreFilter} from 'app/common/classes/StoreFilter';

@Injectable()
export class GlobalServiceDataResolver implements Resolve<any> {

    constructor(
        private $global: GlobalService,
        private $auth: AuthService,
        private $common: CommonService,
        private $abTest: ABTestService,
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.providePayload()
            .mergeMap(() => this.$common.getAllStores())
            .map((result) => {
                this.$global.setChainsAndStores(result);
                return {};
            })
            .mergeMap(() => this.$common.getAllUsers())
            .map((result) => {
                this.$global.setUsers(result);
                return {};
            })
    }

    private providePayload(): Observable<any> {
        return new Observable(observer => {
            try {
                const payload = this.$auth.getPayload();

                this.$global.setChain(payload.chain);
                this.$global.setEmail(payload.email);
                this.$global.setTimezone(payload.timezone);
                this.$global.setStoreName(payload.storeName);
                this.$global.setDisplayName(payload.name ? payload.name : payload.email);
                this.$global.setUserId(payload.id);
                this.$global.setStoreFilter(new StoreFilter(payload.chain, payload.storeName), true);
                this.$global.setFlags(payload.flags || []);
                this.$global.setGroups(payload.groups || []);
                this.$abTest.setFlags(payload.flags || []);

                observer.next();
                observer.complete();
            } catch (e) {
                observer.error(e);
            }
        })
    }
}
