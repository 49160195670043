import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialComponentsSharedModule} from 'app/common/components/material/MaterialComponentsSharedModule';
import {AuthLoginComponent} from './login.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialComponentsSharedModule
    ],
    declarations: [
        AuthLoginComponent
    ],
})
export class AuthLoginModule {
}
