import * as _ from 'lodash';

import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'angular-webstorage-service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

import {StoreFilter} from 'app/common/classes/StoreFilter';
import {NavigationConfig} from 'app/common/interfaces/NavigationConfig';
import {NavigationItem} from 'app/common/interfaces/NavigationItem';

import {environment} from "environments/environment";

import * as navigationConfig from 'config/navigation.json';
import * as permissionsConfig from 'config/permissions.json';

@Injectable({providedIn: 'root'})
export class GlobalService {

    static storageKeys = {
        storeFilter: 'global.storeFilter',
        navigation: 'global.navigation',
    };

    private email: string;
    private chain: string;
    private chainsAndStores: Array<any>;
    private users: Array<any>;
    private timezone: string;
    private storeName: string;
    private displayName: string;
    private userId: string;
    private storeFilter: StoreFilter;
    private storeFilterSource: BehaviorSubject<StoreFilter>;
    private refreshFilterSource: Subject<boolean>;
    private storeFilterRefreshedSource: Subject<boolean>;
    private storeFilterLock: boolean;
    private flags: Array<any>;
    private groups: Array<any>;

    private readonly navigation: NavigationConfig;
    private readonly permissions: Array<string>;

    storeFilter$: Observable<StoreFilter>;
    refreshStoreFilter$: Observable<boolean>;
    storeFilterRefreshed$: Observable<boolean>;

    constructor(
        @Inject(LOCAL_STORAGE) private $storage: StorageService
    ) {
        const localFilter = this.$storage.get(GlobalService.storageKeys.storeFilter) || {chain: null, storeName: null, storeLegibleName: null};
        this.storeFilter = new StoreFilter(localFilter.chain, localFilter.storeName, localFilter.storeLegibleName);
        this.storeFilterSource = new BehaviorSubject<StoreFilter>(this.storeFilter);
        this.storeFilter$ = this.storeFilterSource.asObservable();

        this.refreshFilterSource = new Subject<boolean>();
        this.refreshStoreFilter$ = this.refreshFilterSource.asObservable();

        this.storeFilterRefreshedSource = new Subject<boolean>();
        this.storeFilterRefreshed$ = this.storeFilterRefreshedSource.asObservable();
        this.storeFilterLock = false;

        if (environment.type === 'local') {
            this.permissions = _.union(permissionsConfig.permissions, permissionsConfig.local);
        } else {
            this.permissions = permissionsConfig.permissions;
        }

        const localNavigationConfig = this.$storage.get(GlobalService.storageKeys.navigation) || {collapsedSections: []};
        this.navigation = {
            items: <Array<NavigationItem>>(navigationConfig.items),
            collapsedSections: <Array<string>>(localNavigationConfig.collapsedSections) || []
        };
    }

    public setDisplayName(displayName: string): void {
        this.displayName = displayName;
    }

    public getDisplayName(): string {
        return this.displayName;
    }

    public setEmail(email: string): void {
        this.email = email;
    }

    public getEmail(): string {
        return this.email;
    }

    public setChain(chain: string): void {
        this.chain = chain;
    }

    public getChain(): string {
        return this.chain;
    }

    public getChainsAndStores() {
        return this.chainsAndStores;
    }

    public setChainsAndStores(chainsAndStores) {
        this.chainsAndStores = chainsAndStores;
    }

    public getUsers() {
        return this.users || [];
    }

    public setUsers(users) {
        this.users = users;
    }

    public getFlags() {
        return this.flags || [];
    }

    public setFlags(flags) {
        this.flags = flags;
    }

    public getGroups() {
        return this.groups || [];
    }

    public setGroups(groups) {
        this.groups = groups;
    }

    public setStoreName(storeName: string): void {
        this.storeName = storeName;
    }

    public getStoreName(): string {
        return this.storeName;
    }

    public setTimezone(timezone: string): void {
        this.timezone = timezone;
    }

    public getTimezone(): string {
        return this.timezone;
    }

    public setUserId(userId: string): void {
        this.userId = userId;
    }

    public getUserId(): string {
        return this.userId;
    }

    public lockStoreFilter(lock: boolean) {
        this.storeFilterLock = lock;
    }

    public getStoreFilterLock() {
        return this.storeFilterLock;
    }

    public refreshStoreFilter() {
        this.refreshFilterSource.next(true);
        return new Observable<boolean>(observer => {
            const subscription = this.storeFilterRefreshed$.subscribe(refreshed => {
                observer.next(refreshed);
                observer.complete();
                subscription.unsubscribe();
            })
        })
    }

    public storeFilterRefreshed() {
        this.storeFilterRefreshedSource.next(true);
    }

    public getStoreFilter(): StoreFilter {
        return this.storeFilter;
    }

    public setStoreFilter(filter: StoreFilter, onlyIfEmpty = false) {
        if (!this.storeFilterLock && (!onlyIfEmpty || onlyIfEmpty && this.storeFilter.isEmpty())) {
            this.storeFilter = filter;
            this.$storage.set(GlobalService.storageKeys.storeFilter, filter);
            this.storeFilterSource.next(filter);
        }
    }

    public getNavigationConfig(): NavigationConfig {
        return this.navigation;
    }

    public setNavigationCollapsedSections(collapsedSections: Array<string>) {
        this.navigation.collapsedSections = collapsedSections;
        this.$storage.set(GlobalService.storageKeys.navigation, {collapsedSections})
    }

    public getPermissions(): Array<string> {
        return this.permissions;
    }

}
