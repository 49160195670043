import {Component} from '@angular/core';
import { ABTestService } from 'app/common/ab-test/ab-test.service';
import { ABTestAbstractComponent } from 'app/common/ab-test/ABTestAbstractComponent';

@Component({
    selector: 'app-pages-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends ABTestAbstractComponent {
    constructor(
        protected abTest: ABTestService
    ) {
        super(abTest);
    }
}
