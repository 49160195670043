// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
declare function require(moduleName: string): any;
const { version: appVersion } = require('../../package.json');

export const environment = {
    name: 'Focal Systems Dashboard',
    production: false,
    version: appVersion,
    type: 'staging',
    apiEndpoint: 'https://api-old.staging.focal.dev',
    cdnEndpoint: 'https://cdn.staging.focal.dev',
    labelerEndpoint: 'https://labeler-dev.focal.systems',
    auth2ClientId: '399356895749-0ifdfs3tk4ravbkv2tipg3nnqjlfgq7d'
};
