import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {NgxPermissionsService} from "ngx-permissions";

import {environment} from 'environments/environment';

import {LoginResponse} from './common.service';
import {RequestService} from './request.service';

export const TOKEN_NAME = 'token';

// Loaded from script:
// https://apis.google.com/js/platform.js
declare const gapi: any;

@Injectable({providedIn: 'root'})
export class AuthService {

    static readonly googleHostedDomain = 'focal.systems';

    private googleAuth: gapi.auth2.GoogleAuth;

    private googleAuthError: string;

    constructor(
        private $request: RequestService,
        private jwtHelperService: JwtHelperService,
        private permissionService: NgxPermissionsService
    ) {
        this.initializeGoogleSignIn().then((value) => {
            console.info("Google Sign in loaded");
        }, (googleAuthError: string) => {
            console.error(`Unable to initialize GoogleSignIn ${googleAuthError}`);
        });
    }

    public getToken(): string {
        return localStorage.getItem(TOKEN_NAME);
    }

    public setToken(token: string): void {
        localStorage.setItem(TOKEN_NAME, token);
    }

    public removeToken(): void {
        localStorage.removeItem(TOKEN_NAME);
    }

    public getPayload(): any {
        return this.jwtHelperService.decodeToken();
    }

    public isLoggedIn(): Promise<boolean> {
        const success = Promise.resolve(true);
        const failure = Promise.resolve(false);

        try {
            const tokenExpired = this.jwtHelperService.isTokenExpired();
            if (!tokenExpired) {
                return success;
            }
            return this.isUserSignedInWithGoogle();
        } catch (err) {
            return failure;
        }
    }

    public getGoogleAuth(): Promise<gapi.auth2.GoogleAuth> {
        if (this.googleAuth) {
            return Promise.resolve(this.googleAuth);
        } else if (this.googleAuthError) {
            return Promise.reject(this.googleAuthError);
        } else {
            return this.initializeGoogleSignIn();
        }
    }

    private isUserSignedInWithGoogle(): Promise<boolean> {
        const handleSuccess = () => true;
        const handleError = () => {
            if (this.googleAuth) {
                this.googleAuth.disconnect();
            }
            return false;
        };

        return this.getGoogleAuth()
            .then((googleAuth: gapi.auth2.GoogleAuth) => {
                if (!googleAuth || !googleAuth.isSignedIn.get()) {
                    return false;
                }
                const signAttempt = this.signInGoogleUser(googleAuth.currentUser.get());
                return signAttempt.then(handleSuccess, handleError).catch(handleError);
            }, handleError)
            .catch(handleError);
    }

    private initializeGoogleSignIn(): Promise<gapi.auth2.GoogleAuth> {
        return new Promise((resolve, reject) => {
            gapi.load('auth2', () => {
                const options: gapi.auth2.ClientConfig = {
                    client_id: `${environment.auth2ClientId}.apps.googleusercontent.com`,
                    scope: 'profile email',
                    fetch_basic_profile: false,
                    hosted_domain: AuthService.googleHostedDomain
                };
                gapi.auth2.init(options).then((googleAuth: gapi.auth2.GoogleAuth) => {
                    this.googleAuth = googleAuth;
                    resolve(this.googleAuth);
                }).catch(error => {
                    this.googleAuthError = 'Google sign in failed to load.';
                    reject(this.googleAuthError);
                });
            });
        });
    }

    public signInGoogleUser(googleUser: gapi.auth2.GoogleUser): Promise<void> {
        return new Promise((resolve, reject) => {
            if (googleUser.getHostedDomain() !== AuthService.googleHostedDomain) {
                reject({
                    success: false,
                    message: 'You must sign in using your @focal.systems email.'
                });
            }

            const idToken = googleUser.getAuthResponse().id_token;
            this.$request.post('/v1/auth/google', {idToken})
                .subscribe((data: LoginResponse) => {
                    if (data && data.token) {
                        this.setToken(data.token);
                        resolve();
                    } else {
                        reject({
                            success: false,
                            error: data.error,
                            message: data.message
                        });
                    }
                }, (error: any) => {
                    let message = error.error ? error.error.message : undefined;
                    if (!message) {
                        message = `Unable to perform login request (${error.status}). Please try again later!`;
                    }
                    reject({
                        success: false,
                        error: error.statusText,
                        message: message
                    });
                });
        });
    }

    public logout(): Promise<void> {
        const handleWithTokenRemoval = () => this.removeToken();
        this.permissionService.flushPermissions();
        return this.getGoogleAuth().then(
            (googleAuth: gapi.auth2.GoogleAuth) => {
                return googleAuth && googleAuth.isSignedIn.get() ? googleAuth.signOut() : Promise.resolve();
            })
            .then(handleWithTokenRemoval)
            .catch((googleAuthError: string) => {
                // Log the error, but don't interrupt the logout flow
                console.error(googleAuthError);
                handleWithTokenRemoval();
                return Promise.resolve();
            });
    }

}
