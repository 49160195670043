import { NgModule } from '@angular/core';
import { ABTestService } from 'app/common/ab-test/ab-test.service';


@NgModule({
    declarations: [

    ],
    imports: [

    ],
    providers: [
        ABTestService
    ],
    exports: [

    ],
})
export class AbTestModule {
}
