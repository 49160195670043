import * as _ from 'lodash';

import {Injectable} from '@angular/core';
import {Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild} from '@angular/router';

import {NgxPermissionsService} from "ngx-permissions";

import {AuthService} from '../services/auth.service';
import {GlobalService} from "app/common/services/global/global.service";

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private authService: AuthService,
        private globalService: GlobalService,
        private permissionService: NgxPermissionsService
    ) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.authService.isLoggedIn().then((authenticated: boolean) => {
            const loginRoute = state.url === '/auth/login';
            const passwordRoute = state.url === '/auth/password';

            if (next.routeConfig.loadChildren) {
                return true;
            }

            this.loadPermissionsIfNeeded();

            if (authenticated && loginRoute) {
                this.router.navigate(['/']);
                return false;
            } else if (!authenticated && !loginRoute) {
                this.router.navigate(['/auth/login']);
                return false;
            }

            const flags = this.authService.getPayload() && this.authService.getPayload().flags || [];
            const passwordResetRequired = _.indexOf(flags, 'require_password_reset') >= 0;

            if (authenticated && !passwordRoute && passwordResetRequired) {
                this.router.navigate(['/auth/password']);
                return false;
            }

            if (authenticated && passwordRoute && !passwordResetRequired) {
                this.router.navigate(['/']);
                return false;
            }

            if (next.data.permissions) {
                return this.permissionService.hasPermission(next.data.permissions).then((allowed: boolean) => {
                    if (!allowed) {
                        this.router.navigate(['/']);
                    }
                    return allowed;
                });
            }

            return true;
        }).catch(() => Promise.resolve(false));
    }

    canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(next, state);
    }

    private loadPermissionsIfNeeded() {
        const loaded = this.permissionService.getPermissions();
        if (Object.keys(loaded).length > 0) {
            return;
        }

        const permissions = _.get(this.authService.getPayload(), 'permissions');
        if (permissions) {
            const allPermissions = this.globalService.getPermissions();
            let resolvedPermissions = ['_:loaded'];

            for (const permission of permissions) {
                if (permission === '*:*') {
                    resolvedPermissions = allPermissions;
                    break;
                }
                if (permission.indexOf('*') === -1) {
                    resolvedPermissions.push(permission);
                    continue;
                }
                const rule = permission.split(':');
                for (const entry of allPermissions) {
                    if (entry.startsWith(`${rule[0]}:`)) {
                        resolvedPermissions.push(entry);
                    }
                }
            }

            this.permissionService.loadPermissions(resolvedPermissions);
        }
    }

}
