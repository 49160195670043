import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {FormOptions} from 'app/common/interfaces/FormOptions';
import {Store} from 'app/common/interfaces/Store';
import {RequestService} from 'app/common/services/request.service';
import {observableApiRequest} from 'app/common/utils/service-utils';

// TODO @DEPRECATED

@Injectable({providedIn: 'root'})
export class ApiService {

    constructor(private $request: RequestService) {
    }

    public getFormOptions(dashboard: String, type: String): Observable<FormOptions> {
        return observableApiRequest(this.$request.get(`/forms/${dashboard}/${type}`));
    }

    public getStores(params?: any): Observable<Array<Store>> {
        return observableApiRequest(this.$request.get(`/stores/stores`, {params}));
    }

    public getChains(includeShelfCam: boolean = true): Observable<Array<string>> {
        return observableApiRequest(this.$request.get(`/stores/chains`, {params: {includeShelfCam}}));
    }

    public getStoresByChains(chain: string, includeShelfCam: boolean = true, onlyActiveStores: boolean = true): Observable<Array<Store>> {
        const params = {onlyActiveStores, includeShelfCam};
        return observableApiRequest(this.$request.get(`/stores/store-chains/${chain}`, {params}));
    }

    // public getStoresDevices(storeName: string): Observable<Array<Store>> {
    //     return observableApiRequest(this.$request.get(`/stores/store-devices/${storeName}`));
    // }
    //
    // public getStoresByGeolocation(params: any): Observable<any> {
    //     return observableApiRequest(this.$request.get(`/stores/geolocations`, {params}));
    // }
    //
    // public getStoreSettings(storeName): Observable<any> {
    //     return observableApiRequest(this.$request.get(`/stores/settings/${storeName}`));
    // }

}
