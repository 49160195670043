import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material';
import * as _ from 'lodash';

import {environment} from 'environments/environment';

import {AuthService} from 'app/common/services/auth.service';
import {CommonService, LoginResponse} from 'app/common/services/common.service';
import {forEach} from "@angular/router/src/utils/collection";
import {FileReportService} from "./file-report.service";

@Component({
    selector: 'app-pages-file-report',
    templateUrl: './file-report.component.html',
    styleUrls: ['./file-report.component.scss'],
    providers: [FileReportService],
})
export class FileReportComponent implements OnInit, OnDestroy, AfterViewInit {

    error: String | null;

    isLoading = false;
    hasFilesToUpload = false;
    // temporaryFile = undefined;



    replacedCameraTypes: string[] = ['', 'Shelf Edge - Clip', 'Shelf Edge - Hook', 'Face Mount', 'Riser', 'Hanger'];
    replacedCommBoxParts: string[] = ['', 'Entire Box', 'USB Hub', 'Raspberry Pi', 'Sisterboard', 'Camera Cable/Extender Cable'];
    replaceCameraType = "";
    replaceCommBoxPart= "";
    commBoxRequiredReset = false;
    cablesWereDisconnected = false;
    reflashedSDCard = false;
    reprovisionedDevice = false;
    replacedBattery = false;
    replacedDefectiveCamera = false;
    adjustedCameraAngle = false;
    fixedWiringRelatedIssues = false;
    cameraPortSwap = false;
    cameraRealignment = false;
    other = false;
    otherNotes = "";
    comments = "";
    selectedFiles: File[] = [];
    files = "";
    availableCameraIds: [string]; // all cameraIds for select control
    selectedCameraIds: [string];  // cameraIds selected by user
    cameraIds  = "";              // string of all cameraIds selected
    cameraIdCount = 0;            // count of all cameraIs selected
    cameraId = "";
    chainName = "";
    storeName = "";
    deviceId = ";"
    userName = "";
    startTimeUTC = "";
    stopTimeUTC = "";
    _id = "";
    fileReportFormGroup: FormGroup;
    dialogError = "";

    constructor(
        private $auth: AuthService,
        private $common: CommonService,
        private router: Router,
        private fb: FormBuilder,
        private $service: FileReportService,
        public snackBar: MatSnackBar,
    ) {
        const jsonStr = window.name;
        const json = JSON.parse(jsonStr);
        this.deviceId = json.deviceId;
        this.availableCameraIds = json.cameraIds;
        this.storeName = json.storeName;
        this.chainName = json.chain;
        this.userName = json.userName;
        this._id = json.fileReportId;
        window.name = "File Report";
        this.startTimeUTC = new Date().toISOString();
        this.cameraId = json.cameraId;


        const  formBuilderParameters: {} = {};
        formBuilderParameters["replaceCameraType"] = this.replaceCameraType;
        formBuilderParameters["replaceCommBoxPart"] = this.replaceCommBoxPart;
        formBuilderParameters["commBoxRequiredReset"] = this.commBoxRequiredReset;
        formBuilderParameters["cablesWereDisconnected"] = this.cablesWereDisconnected;
        formBuilderParameters["reflashedSDCard"] = this.reflashedSDCard;
        formBuilderParameters["reprovisionedDevice"] = this.reprovisionedDevice;
        formBuilderParameters["replacedBattery"] = this.replacedBattery;
        formBuilderParameters["replacedDefectiveCamera"] = this.replacedDefectiveCamera;
        formBuilderParameters["adjustedCameraAngle"] = this.adjustedCameraAngle;
        formBuilderParameters["fixedWiringRelatedIssues"] = this.fixedWiringRelatedIssues;
        formBuilderParameters["cameraPortSwap"] = this.cameraPortSwap;
        formBuilderParameters["cameraRealignment"] = this.cameraRealignment;
        formBuilderParameters["other"] = [this.other, ];
        formBuilderParameters["otherNotes"] = this.otherNotes;
        formBuilderParameters["comments"] = this.comments;
        formBuilderParameters["files"] = this.files;
        formBuilderParameters["selectedCameraIds"] = this.cameraIds;

        this.fileReportFormGroup = this.fb.group(formBuilderParameters);
        this.fileReportFormGroup.setValidators([this.fileReportFormValidatorOtherNotes.bind(this)]);

    }

    ngOnDestroy() {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.onStartNewFileReport();
        });
    }

    //#region UI handling

    runSnackBar(message: string, time?: number): void {
        this.snackBar.open(message, 'close', {
            duration: time ? time : 7000
        });
    }

    fileReportFormValidatorOtherNotes(group: FormGroup): any {
        if (group) {
            this.dialogError = "";
            if (group.get("other").value && group.get("otherNotes").value.toString().length < 2) {
                group.get("otherNotes").setValidators([Validators.required, Validators.minLength(2)]);
                this.dialogError = "Must enter data for Other Note.";
                return { OtherNotesfieldisRequired: true };
            }
            else {
                group.get("otherNotes").setValidators(null);
            }

            if ((group.get("replaceCameraType").value.toString().length === 0) &&
                (group.get("replaceCommBoxPart").value.toString().length === 0) &&
                (group.get("commBoxRequiredReset").value === false) &&
                (group.get("cablesWereDisconnected").value === false) &&
                (group.get("reflashedSDCard").value === false) &&
                (group.get("replacedBattery").value === false) &&
                (group.get("replacedDefectiveCamera").value === false) &&
                (group.get("adjustedCameraAngle").value === false) &&
                (group.get("fixedWiringRelatedIssues").value === false) &&
                (group.get("cameraPortSwap").value === false) &&
                (group.get("cameraRealignment").value === false) &&
                (group.get("other").value === false) &&
                (group.get("comments").value.toString().length === 0) &&
                (group.get("files").value.toString().length === 0)) {
                return { NeedToEnterAtLeastOneValueSomewhere: true };
            }

            if ((group.get("replaceCameraType").value.toString().length > 0) &&
                (group.get("selectedCameraIds").value.toString().length === 0)) {
                this.dialogError = "Must select at least one camera.";
                return { NeedToSelectCamerasUpdated: true };
            }
        }
        return null;
    }

    onFileSelected(event) {
        this.selectedFiles = event.target.files;
        if (this.selectedFiles.length > 0) {
            this.hasFilesToUpload = true;
            this.files = "";
            for (let i = 0, file = this.selectedFiles[i]; i < this.selectedFiles.length; i++) {
               this.files +=  file.name;
            }
        }
        else {
            this.hasFilesToUpload = false;
        }
    }

    onStartNewFileReport() {

        if (this._id === undefined || this._id === null) {
            this.$service.postFileReportById(this.storeName, this.deviceId).subscribe(entry => {
                this._id = _.get(entry, "_id", "");
            }, error => {
                this.runSnackBar('Error Adding File Report: ' + error.toString());
            });
        }

    }

    async onSaveFileReport() {
        let isOk = false;
        let isFileUploadOk = true;   // change to false if file upload readded
        let cameraCount = 0;
        const cameras =  this.fileReportFormGroup.get("selectedCameraIds").value.toString();
        if (cameras.length > 0) {
            cameraCount = cameras.split(",").length
        }

        const params = {
            status: "1",
            stopTimeUTC: new Date().toISOString(),
            replaceCameraType: this.fileReportFormGroup.get("replaceCameraType").value.toString(),
            replaceCommBoxPart: this.fileReportFormGroup.get("replaceCommBoxPart").value.toString(),
            selectedCameraIds: this.fileReportFormGroup.get("selectedCameraIds").value.toString(),
            cameraCount: cameraCount.toString(),
            commBoxRequiredReset: this.fileReportFormGroup.get("commBoxRequiredReset").value.toString(),
            cablesWereDisconnected: this.fileReportFormGroup.get("cablesWereDisconnected").value.toString(),
            reflashedSDCard: this.fileReportFormGroup.get("reflashedSDCard").value.toString(),
            reprovisionedDevice: this.fileReportFormGroup.get("reprovisionedDevice").value.toString(),
            replacedBattery: this.fileReportFormGroup.get("replacedBattery").value.toString(),
            replacedDefectiveCamera: this.fileReportFormGroup.get("replacedDefectiveCamera").value.toString(),
            adjustedCameraAngle: this.fileReportFormGroup.get("adjustedCameraAngle").value.toString(),
            fixedWiringRelatedIssues: this.fileReportFormGroup.get("fixedWiringRelatedIssues").value.toString(),
            cameraPortSwap: this.fileReportFormGroup.get("cameraPortSwap").value.toString(),
            cameraRealignment: this.fileReportFormGroup.get("cameraRealignment").value.toString(),
            other: this.fileReportFormGroup.get("other").value.toString(),
            otherNotes: this.fileReportFormGroup.get("otherNotes").value.toString(),
            comments: this.fileReportFormGroup.get("comments").value.toString(),
            selectedFiles: "",
        }


        // call service to perform patch of fields
        await this.$service.putFileReportById(this.storeName, this._id, params).subscribe(entry => {
            // this.temporaryFile = undefined;
            isOk = true;
            if (isOk && isFileUploadOk) {
                window.close();
            }
        }, error => {
            // this.temporaryFile = undefined;
            this.runSnackBar('Error updating File Report: ' + error.toString());
        });

        /*
        // add files selected
        const formData = new FormData();
        if (this.selectedFiles.length) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                formData.append('files', this.selectedFiles[i], this.selectedFiles[i].name);
            }

            // call service to perform post for files
            await this.$service.postFileReportFilesById(this._id, formData).subscribe(entry => {
                // this.temporaryFile = undefined;
                isFileUploadOk = true;
                if (isOk && isFileUploadOk) {
                    window.close();
                }
            }, error => {
                // this.temporaryFile = undefined;
                isFileUploadOk = false;
                this.runSnackBar('Error uploading files for File Report: ' + error.toString());
            });
        }
        */
    }


    saveAdditional() {
    }

    get appName() {
        return environment.name;
    }


    //#endregion


}
