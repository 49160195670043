import {Component} from '@angular/core';

import {environment} from 'environments/environment';

@Component({
    selector: 'app-template-file-report',
    templateUrl: './template-file-report.component.html',
    styleUrls: ['./template-file-report.component.scss']
})
export class TemplateFileReportComponent {

    constructor() {
    }

    get version(): String {
        let version = environment.version;
        if (!environment.production) {
            version += ` ${environment.type}`;
        }
        return version;
    }

}
