import { Observable } from 'rxjs/Observable';

const getData = (r) => r.data;

export const observableApiRequest = (apiRequest, buildResponseFn = getData): Observable<any> => {
    return new Observable(observer => {
        apiRequest.subscribe(
            response => {
                if (response.success || response['status'] === 'ok') {
                    buildResponseFn ? observer.next(buildResponseFn(response)) : observer.next();
                    observer.complete();
                } else {
                    observer.error(response);
                }
            },
            error => {
                observer.error(error);
            }
        )
    });
};
