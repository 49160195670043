import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialComponentsSharedModule} from 'app/common/components/material/MaterialComponentsSharedModule';
import {AuthPasswordComponent} from './password.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialComponentsSharedModule
    ],
    declarations: [
        AuthPasswordComponent
    ],
})
export class AuthPasswordModule {
}
