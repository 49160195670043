import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

import {RequestService} from "app/common/services/request.service";
import {observableApiRequest} from "app/common/utils/service-utils";
import {environment} from "environments/environment";

export interface FileReportItem {
    _id: string;
    deviceId: string;
}

export interface FileReportData {
    success: boolean;
    message: string;
    data: Array<FileReportItem>;
}

@Injectable()
export class FileReportService {

    constructor(private $request: RequestService) {
    }

    public getFileReport(status: number, storeName: string, lookBackDays: number): Promise<Array<FileReportItem>> {
        const url = `${environment.apiEndpoint}/v1/stores/${storeName}/reports`
        const params = {status: status.toString(10), lookBackDays: lookBackDays.toString(10)};
        return new Promise((resolve, reject) => {
            this.$request.get(url, {params}).subscribe(
                response => {
                    if (response['status'] === "ok") {
                        resolve(response['data']);
                    } else {
                        reject({success: false});
                    }
                },
                err => {
                    reject({success: false, error: err});
                }
            );
        });
    }

    public postFileReportById(storeName: string, deviceId: string): Observable<any> {
        const url = `${environment.apiEndpoint}/v1/stores/${storeName}/reports`
        return observableApiRequest(this.$request.post(url, { deviceId }));
    }

    public putFileReportById(storeName: string, _id: string, params: any): Observable<any> {
        const url = `${environment.apiEndpoint}/v1/stores/${storeName}/reports/${_id}`
        return observableApiRequest(this.$request.put(url, params));
    }

}
