import * as _ from 'lodash';

import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component} from '@angular/core';

import {Subscription} from 'rxjs';

import {DropdownOption} from 'app/common/interfaces/DropdownOption';
import {GlobalService} from 'app/common/services/global/global.service';
import {StoreFilter} from 'app/common/classes/StoreFilter';
import {GlobalStoreFilterAwareComponent} from 'app/common/classes/GlobalStoreFilterAwareComponent';

@Component({
    selector: 'app-store-context-selector',
    templateUrl: './store-context-selector.component.html',
    styleUrls: ['./store-context-selector.component.scss'],
    animations: [
        trigger('bodyExpansion', [
            state('collapsed', style({height: '0px', display: 'none'})),
            state('expanded', style({height: '*', display: 'block'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
        ]),
    ]
})
export class StoreContextSelectorComponent extends GlobalStoreFilterAwareComponent {

    private refreshSubscription: Subscription;

    expanded: boolean;
    chainOptions: Array<DropdownOption>;
    storeOptions: Array<DropdownOption>;

    selectedChain: string;
    selectedStore: string;

    constructor(
        protected $global: GlobalService,
    ) {
        super($global);
        this.expanded = false;
        this.chainOptions = [];
        this.storeOptions = [];
    }

    get userName(): string {
        return this.$global.getDisplayName();
    }

    get storeName(): string {
        return this.storeFilter.storeDisplayName;
    }

    get locked(): boolean {
        return this.$global.getStoreFilterLock();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.handleChains();

        this.refreshSubscription = this.$global.refreshStoreFilter$.subscribe(refresh => {
            if (refresh) {
                this.loadData()
            }
        })
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.refreshSubscription.unsubscribe();
    }

    onStoreFilterUpdate(newStoreFilter: StoreFilter) {
        super.onStoreFilterUpdate(newStoreFilter);

        const shouldUpdate = this.selectedChain !== this.storeFilter.chain;

        this.selectedChain = this.storeFilter.chain;
        this.selectedStore = this.storeFilter.storeName;

        if (shouldUpdate) {
            this.loadData();
        }
    }

    loadData() {
        // TODO REFRESH data in $global
        // this.$global.storeFilterRefreshed();
    }

    private handleChains() {
        this.chainOptions = _.chain(this.$global.getChainsAndStores())
            .groupBy('chainName')
            .map((items, key) => ({id: items[0].chainName, name: items[0].chainDisplay}))
            .sortBy('name').value();

        this.selectedChain = _.includes(_.map(this.chainOptions, item => item.id), this.selectedChain) ? this.selectedChain : null;
        if (this.selectedChain) {
            this.updateStoreOptions();
        } else {
            this.storeOptions = [];
            this.selectedStore = null;
        }
    }

    handleChainSelected(chain: string) {
        if (chain !== this.selectedChain) {
            this.selectedChain = chain;
            this.updateStoreOptions();
        }
    }

    private updateStoreOptions() {
        this.storeOptions = _.chain(this.$global.getChainsAndStores())
            .filter(item => item.chainName === this.selectedChain) // TODO should be chain instead of chainDisplay
            .map(item => ({id: item.storeName, name: item.storeDisplay}))
            .sortBy('name').value();
        this.selectedStore = _.includes(_.map(this.storeOptions, item => item.id), this.selectedStore) ? this.selectedStore : null;
    }

    handleStoreSelected(store: string) {
        this.selectedStore = store;
    }

    toggle() {
        this.expanded = !this.expanded;
    }

    canUpdate() {
        const hasSelection = this.selectedChain && this.selectedStore;
        const selectionIsDifferent = this.selectedChain !== this.storeFilter.chain || this.selectedStore !== this.storeFilter.storeName;
        return !this.locked && hasSelection && selectionIsDifferent;
    }

    updateSelector() {
        const store = this.$global.getChainsAndStores().filter(item => item.storeName === this.selectedStore);
        if (store && store.length > 0) {
            const storeLegibleName = store[0].storeDisplay || store[0].storeName;
            this.$global.setStoreFilter(new StoreFilter(this.selectedChain, this.selectedStore, storeLegibleName));
            this.expanded = false;
        }
    }

}
