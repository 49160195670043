import {CdkAccordionModule} from '@angular/cdk/accordion';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {GestureConfig} from '@angular/material';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JwtModule} from '@auth0/angular-jwt';
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {StorageServiceModule} from 'angular-webstorage-service';

import {AppRoutingModule} from 'app/app-routing.module';
import {AppComponent} from 'app/app.component';
import {AbTestModule} from 'app/common/ab-test/ab-test.module';
import {MaterialComponentsSharedModule} from 'app/common/components/material/MaterialComponentsSharedModule';
import {NavigationComponent} from 'app/common/components/navigation/navigation.component';
import {StoreContextSelectorComponent} from 'app/common/components/store-context-selector/store-context-selector.component';
import {AuthErrorHandler} from 'app/common/errorHandlers/auth/auth.errorHandler';

import {AuthInterceptor} from 'app/common/interceptors/auth.interceptor';
import {TokenInterceptor} from 'app/common/interceptors/token.interceptor';

import {ApiService} from 'app/common/services/api/api.service';
import {AuthService} from 'app/common/services/auth.service';
import {CommonService} from 'app/common/services/common.service';
import {GlobalService} from 'app/common/services/global/global.service';
import {RequestService} from 'app/common/services/request.service';

import {TemplateDefaultComponent} from 'app/common/template-default/template-default.component';
import {TemplateLoginComponent} from 'app/common/template-login/template-login.component';
import {TemplateFileReportComponent} from 'app/common/template-file-report/template-file-report.component';
import {NgxPermissionsModule} from 'ngx-permissions';

export function jwtTokenGetter() {
    return localStorage.getItem('token');
}

const jwtConfig = {
    config: {
        whitelistedDomains: [
            'localhost:5000',
            'api.focal.dev',
            'api.features.focal.dev',
            'api.focal.systems',
            'api-new.focal.systems',
            'api-old.staging.focal.dev'
        ],
        tokenGetter: jwtTokenGetter
    }
};

@NgModule({
    declarations: [
        AppComponent,
        TemplateDefaultComponent,
        TemplateLoginComponent,
        TemplateFileReportComponent,
        NavigationComponent,
        StoreContextSelectorComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        CdkAccordionModule,
        HttpClientModule,
        JwtModule.forRoot(jwtConfig),
        LoadingBarRouterModule,
        NgxPermissionsModule.forRoot(),
        StorageServiceModule,
        AbTestModule,
        MaterialComponentsSharedModule
    ],
    providers: [
        ApiService,
        AuthService,
        CommonService,
        GlobalService,
        RequestService,
        {provide: ErrorHandler, useClass: AuthErrorHandler},
        {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
