import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";

import {AuthService} from "app/common/services/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(event => {
                const authService = this.injector.get(AuthService);
                if (event instanceof HttpResponse && event.headers.has('X-New-JWT-Token')) {
                    authService.setToken(event.headers.get('X-New-JWT-Token'));
                }
                return event;
            })
        );
    }

}
