import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialComponentsSharedModule} from 'app/common/components/material/MaterialComponentsSharedModule';
import {FileReportComponent} from './file-report.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialComponentsSharedModule,
    ],
    declarations: [
        FileReportComponent
    ],
})
export class FileReportModule {
}
