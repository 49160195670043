import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { filter, map, mergeMap } from 'rxjs/operators';
import { environment } from 'environments/environment';

/**
 * Main application component.
 * @author Łukasz Milewski <lukasz@focal.systems>
 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor(
        public router: Router,
        private titleService: Title,
        private activatedRoute: ActivatedRoute
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd), map(() => this.activatedRoute))
            .pipe(map(route => { while (route.firstChild) { route = route.firstChild; } return route; }))
            .pipe(filter(route => route.outlet === 'primary'), mergeMap(route => route.data))
            .subscribe(event => this.setTitle(event['title']));
    }

    private setTitle(title: string) {
        if (title && title.trim().length > 0) { title = `${title} › `; }
        this.titleService.setTitle(`${title || ' '}${environment.name}`);
    }

}
