import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router} from '@angular/router';

import {environment} from 'environments/environment';

import {AuthService} from 'app/common/services/auth.service';
import {GlobalService} from 'app/common/services/global/global.service';

@Component({
    selector: 'app-template-default',
    templateUrl: './template-default.component.html',
    styleUrls: ['./template-default.component.scss'],
})
export class TemplateDefaultComponent implements OnInit {

    constructor(
        private router: Router,
        private titleService: Title,
        private authService: AuthService,
        private globalService: GlobalService
    ) {
    }

    ngOnInit(): void {
    }

    logout() {
        this.authService.logout().then(() => {
            this.router.navigate(['/auth/login']);
        });
    }

    get appName(): String {
        return environment.name;
    }

    get userId(): String {
        return this.globalService.getUserId();
    }

    get title(): String {
        let title = this.titleService.getTitle();
        const appNameInTitle = `› ${this.appName}`;
        if (title.endsWith(appNameInTitle)) {
            title = ' ' + title.substr(0, title.length - appNameInTitle.length);
        }
        return title;
    }

    get version(): String {
        let version = environment.version;
        if (!environment.production) {
            version += ` ${environment.type}`;
        }
        return version;
    }

}
